import React from 'react'
import './terms.css'

export const Terms = () => {
	return (
		<>
			<section className='terms-2'>
				<div className='terms-title-2'>Terms and Conditions</div>

				<div className='terms-contract-block'>
					CONTRACT on the conditions for access to the Service for making money
					transfers (offer)
				</div>
				<div className='descr-block'>
					<div className='terms-contract-descr'>City of Vilnius</div>
					<div className='terms-contract-descr'>UAB "VivatPay"</div>
					<div className='terms-contract-descr'>
						Registration number 30645510
					</div>
					<div className='terms-contract-descr'>Final Terms and Conditions</div>
				</div>


				<div className="terms-title-3">1. TERMS AND DEFINITIONS</div>

				<div className="terms-text">Offer - a public proposal by the Agent addressed to any individual who has reached the age of eighteen and is legally capable, working in service and service organizations, to conclude this agreement (hereinafter referred to as the Agreement) on the existing terms contained in the Agreement.</div>
				<div className="terms-text">Acceptance - the complete and unconditional acceptance by the Client of the current terms of the Agreement.
				Service (https://vivatpay.eu/) - an electronic service that allows Clients to voluntarily transfer funds to Recipients. The right to use the Service without location restrictions, without the right to modify or otherwise process, and without the right to distribute is provided free of charge.</div>
				<div className="terms-text">Recipient (Tip Recipient) – an individual who is fully capable and has reached the age of eighteen (Service Worker) providing service to Clients (Guests) on the premises of the Enterprise and who has accepted the terms of this Agreement with the Agent and is the recipient of the funds transferred by the Clients.</div>
				<div className="terms-text">Client (Guest) – an individual who makes a money transfer to the Recipient as a tip through an order to their bank or card account for the transfer of funds to the Agent. By making such a transfer of funds, the Client also authorizes the Agent to transfer the funds received to the Recipient at their request, according to the procedures and conditions of this Agreement. A Client can be any fully capable individual who has reached the age of eighteen.</div>
				<div className="terms-text">Funds – cashless funds or electronic funds.</div>
				<div className="terms-text">Transfer – within the framework of this Agreement, a transfer refers to the voluntary and gratuitous transfer of funds by the Client as a form of encouragement to the Recipient (hereinafter referred to as Tips) through the Agent. The transfer is not related to the entrepreneurial activities of the Client and the Recipient.</div>
				<div className="terms-text">Tips – the provision of monetary gifts that the Client (Guest) voluntarily (at their discretion) gives to the individual serving (Recipient) on the premises of the Enterprise and disconnected from the payment for the services of the Enterprise.</div>
				<div className="terms-text">Enterprise – a legal entity or an individual entrepreneur that sells goods (works, services) to the public.</div>

				<div className="terms-title-3">2. SUBJECT OF THE AGREEMENT</div>
				<div className="terms-text">2.1. This Agreement has a mixed nature and combines elements of a license agreement for using software and an agency agreement, therefore:</div>
				<div className="terms-text">2.1.1. Under this Agreement, the Agent provides informational and technological support related to the processing of voluntary gratuitous transfers of funds from Clients to the Agent with subsequent transfer to the Recipient and transfers funds to the Recipient's bank (credit organization).</div>
				<div className="terms-text">2.2. Under this Agreement, the Agent also acts as the Licensor (developer and sole legal owner of the Service as a program), and the Recipient as the Licensee, who receives the right to use a simple limited non-exclusive gratuitous license for the Service for the entire duration of the Agreement, solely for realizing the functionality embedded in the Service and taking into account the terms of the Agreement.</div>
				<div className="terms-text">2.3. Under this Agreement, the Agent acts on behalf of and at the expense of the Client. Reports on completed transactions are available to the Recipient through the Service on the portal vivatpay.eu.</div>
				<div className="terms-text">2.4. The Recipient guarantees that they have the right to receive funds, and are not encumbered by obligations for those funds before third parties.</div>
				<div className="terms-text">2.5. By accepting this offer, the Parties acknowledge the fact of the Recipient's registration in the Service and the submission of their payment information into the Service. These actions are sufficient to recognize their accession to the Agreement. The Recipient also accepts the Privacy Policy. When processing personal data, the Company is guided by Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (the “General Data Protection Regulation”), by the Law of the Republic of Lithuania on the Legal Protection of Personal Data, and by the requirements laid down in other legal acts governing the processing and protection of personal data.The Company’s Privacy Policy is available at: https://vivatpay.eu/privacy-policy. By submitting a Registration Form the Client also acknowledges the Privacy Policy by ticking the checkbox.</div>
				<div className="terms-text">2.6. The procedure for entering the Recipient's payment details referred to in paragraph 2.5 is conducted through secure information channels and is only available to the bank that issued the card (in the case of using a bank card) or the payment system (in the case of using a payment system). Information about the payment medium's details is not accessible to the Agent due to the specifics of the operation of banks and/or payment systems.</div>
				<div className="terms-text">2.7. The commission fee of the Agent for informational and technological support of the process and execution of the transfer of money from Agent to Recipient under this Agreement is 0 euros, and the transfer operation is carried out free of charge. The cost of crediting funds to the Agent’s account (charged to the Recipient at the time of transfer) ranges from 0% to 7% of the transfer amount. If the Client (Guest) does not uncheck the “I am ready to pay the commission” option shown in the user interface of the Service during payment, the cost of crediting funds to the Agent’s account (charged to the Recipient at the time of transfer) is 7% of the transfer amount. If the Client (Guest) unchecks the specified option, the cost of crediting funds to the Agent’s account is 0%.</div>
				<div className="terms-text">2.8. In the event of the suspension of Service operations, the Agent notifies the Recipient by any of the following means: by email: vivatpay@gmail.com;by phone: +370 692 10831;</div>
				<div className="terms-text">Within no later than 5 days after receiving notification of the suspension of the Service, the User must withdraw the remaining funds from their Account. If the User does not withdraw the remaining balance from their Account within the specified time frame, the Agent terminates the Agreement and appropriates the remaining funds on the Account.</div>

				<div className="terms-title-3">3. REGISTRATION ON THE SERVICE. RECIPIENT ACCOUNT</div>
				<div className="terms-text">3.1. Registration on the Service is understood as actions by the Recipient related to filling out the following registration details: First Name, Last Name, Gender, Phone Number, E-mail, Position, Photo. The Recipient's registration is also possible by authorizing on the Service through a personal account on a social network, provided that missing data are added in accordance with the requirements of the Service.</div>
				<div className="terms-text">3.2. The Recipient agrees to provide accurate information about themselves during registration and to keep this information up to date; otherwise, the Agent has the right at their discretion to refuse the Recipient registration or to delete the corresponding account.</div>
				<div className="terms-text">3.3. The Agent reserves the right to request confirmation of the data provided during registration at any time from the Recipient and, in this regard, to request supporting documents (in particular, identity verification documents), the failure to provide which, at the discretion of the Agent, may be equivalent to providing inaccurate information. The Agent has the right to unilaterally terminate this Agreement if the Recipient provides inaccurate information.</div>
				<div className="terms-text">3.4. The Recipient agrees not to use in the Service, including in their login, trademarks (service marks) that they do not own. The Agent has the right to prohibit the use of certain logins, URLs, as well as to establish additional requirements for them (length, permissible symbols, etc.).</div>
				<div className="terms-text">3.5. The Recipient agrees to use the account personally. Transfer of access to their account to third parties is prohibited.</div>
				<div className="terms-text">3.6. The Recipient must immediately notify the Agent of any unauthorized access (not permitted by the Recipient) to the Recipient's account and/or any violation (suspicions of violation) of the confidentiality of their access means to the account. For security reasons, the Recipient must independently perform a secure logout from their account at the end of each session in the Service (if applicable). The Agent is not responsible for any loss or corruption of data, as well as any other consequences of any kind that may arise from the Recipient's violation of the provisions of this clause of the Agreement.</div>
				<div className="terms-text">3.7. The Recipient has the right to delete their account at any time independently via the "Edit Data" Section on the Service website and in the app. The account can also be deleted by personally contacting the Support Service at the numbers indicated on the Service website. After confirming the procedure, the account will be deleted within 30 days.</div>
				<div className="terms-text">3.8. The account, as well as the Service as a whole, may only be used to receive funds, including electronic ones, as tips. The use of the Service and the Account for the purpose of receiving funds in connection with entrepreneurial or commercial activities is prohibited.</div>
				<div className="terms-text">In the event that the Agent has suspicions, independently or with the participation of a partner bank, that the transfers are being conducted for any purposes other than those of gifting or donation, the Agent, as well as the partner bank, has the right to refuse to execute the Recipient's order to make the transfer, block the funds in the account, and demand that the Recipient provide information confirming the nature of the transfer. Such information includes, among other things, but is not limited to – documented information about the Recipient's employment, information confirming the Recipient's ownership of the bank card to which the transfer is to be made, identity documents of the Recipient, etc.</div>
				<div className="terms-text">The time for providing documents at the request of the Agent is 10 (ten) business days. The request for documents is sent to the Recipient via the Recipient’s personal cabinet or email. The format for providing documents is electronic copies, photographs, etc. If the documents are not provided, the Agent will return the funds in the amount of the blocked sum to the Payer within a term not exceeding 90 calendar days from the expiration of the term for the Recipient to provide the requested documents.</div>
				<div className="terms-text">By accepting the terms of this offer, the Recipient confirms and acknowledges their full agreement with the terms set forth in this clause, thus accepting the terms in full; partial acceptance of the terms of this offer is not permitted.</div>


				<div className="terms-title-3">4. RIGHTS AND OBLIGATIONS OF THE PARTIES</div>

				<div className="terms-text">4.1. The Recipient agrees to:</div>
				<div className="terms-text">4.1.1. Not engage in actions aimed at compelling Clients to transfer funds, leave positive reviews, or take any other actions that might affect the trust of Clients towards the Service;</div>
				<div className="terms-text">4.1.2. Comply with the requirements of this Agreement;</div>
				<div className="terms-text">4.1.3. In the event of any claims, within 3 (Three) calendar days from the moment of receiving the funds, submit them to the Agent at the email address vivatpay@gmail.com;</div>
				<div className="terms-text">4.1.4. Adhere to the principles of good conduct. Reviews and comments in the Service are intended solely to express gratitude through words or to indicate the need for correcting any deficiencies in order to improve the quality of service in establishments and enterprises in the service and entertainment sectors;</div>
				<div className="terms-text">4.1.5. Not post on the Service materials and information that violate current legislation, including but not limited to the following: pornography, defamation, insults, calls to violence, overthrow of authority, spamming, malicious programs;</div>
				<div className="terms-text">4.1.6. Provide TIN, registration address, and actual bank details for making transfers to the Recipient.</div>
				<div className="terms-text">4.1.7. The Recipient may not use the Service for operations related to financing the political and public activities of any individuals, including non-profit and public organizations aimed at systematically deriving profit or concealing income. The Recipient is aware of criminal and administrative liability for conducting entrepreneurial activities without registration or in violation of registration rules, breaching licensing requirements and conditions, as well as violating the requirements of the legislation on cash settlements.</div>
				<div className="terms-text">4.2. The Agent agrees to:</div>
				<div className="terms-text">4.2.1. Accept and process payments on behalf of the Recipient;</div>
				<div className="terms-text">4.2.2. Ensure the transfer of funds to the Recipient;</div>
				<div className="terms-text">4.2.3. Payments to the Recipient occur in the following order: 1) sums of Client payments are aggregated by the Agent (and reflected in the account of the respective Recipient); 2) payment is made when at least 5 euros are accumulated; 3) the payment amount may not exceed 600 euros for a single payment; 4) the Recipient makes a request for payment using the Service; upon correct entry of all data and with available balance, the Agent makes such payment;</div>
				<div className="terms-text">4.2.4. The funds transferred to the Recipient are not compensation or income for the Agent, except for the commission charged under this Agreement;</div>
				<div className="terms-text">4.2.5. Fully and timely fulfill all obligations accepted under this Agreement;</div>
				<div className="terms-text">4.2.6. Immediately block the Recipient's account in the event of violations by Clients of clauses 4.1.1, 4.1.4, 4.1.5 of the Agreement. In this case, the Agent is not liable for such violations by the Recipient and has the right to pass the Recipient's data in accordance with the procedure and order established by applicable law to a person who will file claims for the aforementioned violations.</div>
				<div className="terms-text">4.2.7. Calculate and pay all taxes and fees stipulated by current legislation upon transferring monetary amounts in favor of the Recipient.</div>
				<div className="terms-text">4.2.8. The Agent has the right to process any personal data provided upon joining these Conditions by any means in order to fulfill this Agreement with the Recipient.</div>


				<div className="terms-title-3">5. TERMINATION OF THE AGREEMENT</div>


				<div className="terms-text">5.1. The Agreement may be terminated: at the initiative of either Party in the event of the other Party violating the terms of the Agreement; for any other reasons provided by the Agreement, as well as by current legislation.</div>
				<div className="terms-text">5.2. The User has the right to refuse to use the Service at any time by deleting their account in the "Edit Data" Section on the Service website and in the app. The account can also be deleted by personally contacting the Support Service at the numbers indicated on the Service website. After the procedure is confirmed, the account will be deleted within 30 days.</div>

				<div className="terms-title-3">6. LIABILITY AND LIMITATION OF LIABILITY</div>

				<div className="terms-text">6.1. The Parties are liable for violating the terms of the Agreement as established by the Agreement and current legislation.</div>
				<div className="terms-text">6.2. The aggregate liability of the Agent under the Agreement, including penalties (fines, forfeits) and/or reimbursable damages for any claims or complaints regarding the Agreement or its execution, is limited to the value of payments for the month in which the losses occurred, provided that such losses are documented.</div>
				<div className="terms-text">6.3. The Agent is not liable for: losses and/or lost profits of the Recipient and/or third parties while using the Service or the impossibility of such usage, regardless of whether the Agent could foresee the possibility of such losses or not; any delays, interruptions, damage, or losses occurring due to: defects in any electronic or mechanical equipment not owned by the Agent; problems in data transmission or connection not due to the Agent's fault.</div>
				<div className="terms-text">6.4. In the event of force majeure circumstances, which include: natural disasters, accidents, fires, mass riots, strikes, military actions, wrongful acts by third parties, the enactment of legislative acts, government decrees and directives of government bodies directly or indirectly prohibiting the activities specified in this Agreement, hindering the Parties from fulfilling their functions under this Agreement, and other circumstances beyond the control of the Parties, they are released from liability for non-fulfillment of obligations assumed if within 10 days from the occurrence of such circumstances and provided there is a connection, the Party affected by their influence notifies the other Party of what has occurred, as well as makes all efforts to eliminate the consequences of the force majeure circumstances as soon as possible.</div>
				<div className="terms-text">6.5. The Service is the result of the Agent’s intellectual activity and is protected by the Law of the Republic of Lithuania.</div>
				<div className="terms-text">6.6. No elements are used in the Service that violate the rights of third parties.</div>
				<div className="terms-text">6.7. The Recipient agrees that no software is free from errors. The Service is provided with standard features for all Clients, Recipients, and partners based on the generally accepted principle “as is.” The Agent makes no guarantees, either express or implied, that the software will meet the Recipient's requirements or expectations, or will correspond to the Recipient's goals and objectives.</div>
				<div className="terms-text">6.8. The Client agrees that in order to work with the software, the Recipient must use software (web browsers, operating systems, etc.) and hardware (personal computers, network equipment, etc.) produced and provided by third parties, and the Agent is not responsible for the quality of their operation.</div>
				<div className="terms-text">6.9. The Recipient guarantees that they will not copy, modify, translate, decompile, disassemble, reconstruct, or in any other way attempt to convert the object code of the software into a human-readable form or produce any derivative actions, nor allow anyone else to do so, except as explicitly provided by applicable law.</div>
				<div className="terms-text">6.10. In the event of loss and/or disclosure of the Recipient's login and password for accessing the Service, the Recipient assumes the risk of any adverse consequences that may arise.</div>
				<div className="terms-text">6.11. As for the implementation of the payment infrastructure, the operation of the Service is built exclusively on the financial services of partners and is not subject to the requirements for opening non-bank credit institutions (NBFI).</div>
				<div className="terms-text">6.12. The Recipient agrees that the Agent is not liable for failures in the operation of payment systems and Partners that directly conduct the transfers themselves.</div>
				<div className="terms-text">6.13. The Agent does not process on its side the data of Clients' and Recipients' bank cards, and certainly does not store such data on its side. The processing of card data entry and the conduct of payments is handled by Partners. The Agent only retains non-critical information about completed transactions - amount, time of transaction, status, and possibly the location of the Client and Recipient at the time of payment.</div>
				<div className="terms-text">6.14. The Recipient acknowledges and understands that by entering into this Agreement, they are hiring the Agent to organize the reception of Tips received as encouragement.</div>
				<div className="terms-text">6.15. The period for the Agent to review a refund request is 7 days. Refunds are made to the same bank card from which the payment was made. Funds are credited to the card within a period of 5 to 30 banking days, depending on the Bank that issued the bank card. Based on the results of the review of the request, the Platform reserves the right to refuse the Payer a refund.</div>
				<div className="terms-text">6.16. The Agent is not responsible for the suspension or termination of the Service concerning any User of the Service.</div>


				<div className="terms-title-3">7. PAYMENT SECURITY GUARANTEES</div>
				
				<div className="terms-text">7.1. Agent protects and processes the Client's and the Client's bank card data according to the PCI-DSS security standard to ensure the secure processing of cardholder data. Data transmission takes place over secure banking networks that have the highest reliability level. Confidential data necessary for payment (card details, registration data, etc.) are not made available to the Agent. The Partner does not transfer Clients' bank card data to other third parties. To further authenticate the cardholder, the 3D Secure protocol is used, as well as Google Pay and Apple Pay authentication technologies.</div>
				<div className="terms-text">7.2. In case of any questions regarding completed payments, the Recipient can contact customer support at vivatpay@gmail.com.</div>
				<div className="terms-text">7.3. The security of internet payment processing is guaranteed by the Partner(s). All operations with payment cards are carried out in accordance with the requirements of Visa International and MasterCard WorldWide.</div>

				<div className="terms-title-3">8. DISPUTE RESOLUTION</div>


				<div className="terms-text">8.1. All disputes between the Company and the User shall be settled in good faith by negotiation, and in case of inability to reach an agreement – in a competent court at the address of the Company’s location in accordance with the material law of the Republic of Lithuania unless otherwise is stated in the executed Agreement between Company and User. In case of unresolved disagreements, the Parties agreed to adhere to the claim procedure, with the claim review period being 5 (five) business days from the moment it is received.</div>
				<div className="terms-text">8.2. If disputes remain unresolved, the Parties agreed to resolve disputes in a court in the Agent's location.</div>


				<div className="terms-title-3">9. CONFIDENTIALITY</div>

				<div className="terms-text">9.1. Each of the Parties agrees not to disclose confidential information received from the other Party in connection with the execution of the Agreement.</div>
				<div className="terms-text">9.2. For the purposes of this Agreement, "Confidential Information" means all information concerning the secrets, business relations, transactions, or connections of the Recipient (the Party providing that information), the data about which can be received by the Agent (the Party receiving that information) in any format or on any medium.</div>
				<div className="terms-text">9.3. In return for the Recipient's agreement to provide information to the Agent under the Agreement, the Agent agrees to ensure that all Confidential Information of the Recipient:</div>
				<div className="terms-text">9.3.1. is stored as confidential and is not reproduced or copied (beyond that reasonably necessary for the purposes of the Agreement);</div>
				<div className="terms-text">9.3.2. will not be transferred, disclosed, or otherwise made available to any other third Party (except for appointed officers, employees, agents of the Agent who need to know and use the Confidential Information for the purposes of the Agreement);</div>
				<div className="terms-text">9.3.3. is not used for any other purpose except for the purposes of the Agreement;</div>
				<div className="terms-text">9.3.4. is stored with due security measures.</div>
				<div className="terms-text">9.4. The provisions of this Agreement do not apply to information that:</div>
				<div className="terms-text">9.4.1. is or becomes public knowledge for reasons not related to violations by the Agent;</div>
				<div className="terms-text">9.4.2. is disclosed for distribution by the Recipient;</div>
				<div className="terms-text">9.4.3. must be disclosed in accordance with law or any regulatory act of the Executive authority;</div>
				<div className="terms-text">9.4.4. became known or was known to the Agent without the Agent violating any obligations regarding confidentiality.</div>
				<div className="terms-text">9.5. The Parties acknowledge that in the interest of preserving Confidential Information or preventing the disclosure of any other information that, in the opinion of the Recipient, should be maintained in secrecy, they have the right to conceal, mask, or in any other way alter this information. The Recipient must keep the structure and format of the provided data unchanged.</div>
				<div className="terms-text">9.6. In the event that the Agent becomes aware of facts of unlawful copying, disclosure, or use of any Confidential Information of the Recipient, the Agent agrees to promptly inform the Recipient, and if the Recipient requests, to take measures necessary to prevent further unlawful copying, disclosure, or use.</div>
				<div className="terms-text">9.7. The Agent's obligations under this Agreement are indefinite and remain in effect for an indefinite period.</div>

				<div className="terms-title-3">10. PERSONAL DATA</div>

				<div className="terms-text">10.8. By providing their personal data (last name, first name, contact details, and others), the Recipient agrees to their processing by the Agent. The Agent agrees not to disclose the personal data obtained from the Recipient. It is not considered a violation 1) to provide the Agent with the Recipient's personal data and relevant information to third parties acting on the basis of agreements with the Agent to fulfill the contractual obligations to the Recipient, 2) to provide information according to reasonable and applicable requirements by the Law of the Republic of Lithuania.</div>
				<div className="terms-text">10.9. The Agent hereby informs the Party that by accepting this offer, the Party grants the Agent the right to process personal data for the duration of this Agreement and after its termination, the storage period is determined by the legislation of the Republic of Lithuania.</div>
				<div className="terms-text">10.10. When processing personal data, the Company is guided by Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (the “General Data Protection Regulation”), by the Law of the Republic of Lithuania on the Legal Protection of Personal Data, and by the requirements laid down in other legal acts governing the processing and protection of personal data.
				The Company’s Privacy Policy is available at: https://vivatpay.eu/privacy-policy. By submitting a Registration Form the Client also acknowledges the Privacy Policy by ticking the checkbox.</div>
				<div className="terms-text">10.11. The purpose of processing Personal data received from the Client (Guest) and/or the Recipient, in all cases, is to fulfill the Agreement.</div>
				<div className="terms-text">10.12. The Parties understand the processing of personal data as their collection, recording, systematization, accumulation, storage, clarification (updating, modification), extraction, use, transfer, including cross-border (distribution, provision of access) of such personal data to affiliated or related entities of the Agent, anonymization, blocking, removal, destruction of personal data while ensuring confidentiality and security during processing.</div>


				<div className="terms-title-3">11. USE OF ELECTRONIC SIGNATURE</div>
		
				<div className="terms-text">11.1. Any actions of the Recipient using the Recipient's login and password to the account or the personal email address specified in the Service (the electronic signature key) confirm the fact of an analogue of the handwritten signature (AHS) directly by the Recipient.</div>
				<div className="terms-text">11.2. Electronic documents signed by AHS are recognized as equivalent to documents on paper signed by handwritten signature.</div>
				<div className="terms-text">11.3. The Recipient agrees to maintain the confidentiality of the AHS (not to transfer their login and password or provide access to their email to third parties) and to take all necessary measures to keep this information confidential and is fully responsible for its safekeeping and personal use, independently choosing the way of storage and limiting access to it.</div>
				<div className="terms-text">11.4. In the case of unauthorized access to the login and password, their loss, or disclosure to third parties, the Recipient must immediately notify the Agent by sending an email from the email address specified in their account.</div>
				<div className="terms-text">11.5. The Recipient fully bears the risk of any adverse consequences that may arise due to non-fulfillment of the obligations provided in clauses 11.3 - 11.4 of this Agreement, including risks related to negative consequences from dishonest actions of third parties who have obtained the aforementioned information.</div>

				<div className="terms-title-3">12. FINAL PROVISIONS</div>
				<div className="terms-text">12.1. The applicable law to the relations related to or arising from the operation of the Agreement is the law of the Republic of Lithuania.</div>
				<div className="terms-text">12.2. The Agreement shall come into force from the moment the Recipient accepts the terms of the Agreement (clause 2.5 of the Agreement) and shall remain in effect until any of the conditions specified in clause 5.1 of the Agreement occur.</div>
				<div className="terms-text">12.3. The Agent reserves the right to amend the terms of the Agreement and/or revoke the Agreement at any time at their discretion. In case the Agent makes changes to the Agreement, such changes shall take effect from the moment the amended text of the Agreement is posted in the Service.</div>
				<div className="terms-text">12.4. In the case that registration in the Service is performed by the Recipient's employer (registration in the Business section on the website https://vivatpay.eu/), it is presumed that such actions are performed by the authorized employee of the Recipient's employer acting on behalf of the respective legal entity or individual entrepreneur. Registration in the Business section of the Service means consent of the respective legal entity or individual entrepreneur, on behalf of which registration actions are performed, for the Agent to use the trademark and/or trade name and/or service mark of the respective legal entity for informational purposes, including posting on the website https://vivatpay.eu/ and notifying an unlimited circle of individuals about registration in the Service.</div>


				<div className="terms-title-3">13. INFORMATION</div>
				<div className="terms-title-3">ABOUT US</div>

				<div className="terms-text">https://vivatpay.eu/ is a website operated by UAB VivatPay, trading as VivatPay, which is a company incorporated and registered in the Republic of Lithuania with a company registration number of 306455100 and whose registered office is located at Kareivių str. 11B, LT-09133, Vilnius, Lithuania.</div>

				<div className="terms-title-3">CONTACT US</div>
				<div className="terms-text">To contact us, please email vivatpay@gmail.com</div>

			</section>
		</>
	)
}
